@import 'normalize.css';

:root {
  --acc-nav-header-height: 60px;
}

@media (min-width: 768px) {
  :root {
    --acc-nav-header-height: 78px;
  }
}

@media (min-width: 1025px) {
  :root {
    --acc-nav-header-height: 0;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 14px;
}

body {
  background: #fff;
  color: #0c161c;
  font-family: oscine, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  display: grid;
  grid-template-areas: 'nav main';
  grid-template-columns: auto 1fr;
}

@media (min-width: 1025px) {
  html,
  body {
    font-size: 16px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

#acc-nav {
  position: relative;
  z-index: 1;
  grid-area: nav;
}

main {
  position: relative;
  grid-area: main;
  display: grid;
}

#acc-nav + main {
  /* Ensure nav does not overlay content */
  margin-top: var(--acc-nav-header-height);
}

#single-spa-application {
  height: 100%;
}
